import store from '@/state/store';
import { setUserAction } from '../helpers/userActions/userActions';
import { roleGuard } from './guards/roleGuard';
import { clientPassGuard } from './guards/clientPassGuard';
import { operatorPassGuard } from './guards/operatorPassGuard';
import i18n from '../i18n';
import { PermissionsModules } from '../helpers/CONSTANTS';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home',
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/pages/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home',
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home',
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          store.dispatch('auth/logOut');
        } else if (process.env.VUE_APP_DEFAULT_AUTH === 'fakebackend') {
          store.dispatch('authfack/logout');
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push('/login')
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
              name: 'home',
            }
            : {
              ...routeFrom,
            }
        );
      },
    },
  },
  {
    path: '/',
    name: 'home',
    meta: {
      name: 'Inicio',
      authRequired: true,
      module: PermissionsModules.DASHBOARD,
    },
    component: () => import('../views/pages/dashboard/index'),
    beforeEnter: roleGuard,
  },

  {
    path: '/utility/maintenance',
    name: 'maintenance',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/maintenance'),
  },
  {
    path: '/utility/comingsoon',
    name: 'comingsoon',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/comingsoon'),
  },
  {
    path: '/utility/404',
    name: 'error-404',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/404'),
  },

  {
    path: '/auth/lock-screen',
    name: 'lock-screen',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/auth/lock-screen'),
  },
  {
    path: '/auth/recoverpwd',
    name: 'recoverpwd',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/auth/recoverpwd'),
  },

  /* RUTAS USERS*/
  {
    path: '/users',
    name: 'user-index',
    meta: {
      name: 'Usuarios',
      authRequired: true,
      module: PermissionsModules.USERS
    },
    component: () => import('../views/pages/users/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/users/:id',
    name: 'user-show',
    meta: {
      authRequired: true,
      module: PermissionsModules.USERS
    },
    component: () => import('../views/pages/users/show'),
    beforeEnter: roleGuard,
  },
  /* RUTAS CURRENCIES*/
  {
    path: '/currencies',
    name: 'currencies-index',
    meta: {
      authRequired: true,
      name: 'Monedas',
      module: PermissionsModules.CURRENCIES
    },
    component: () => import('../views/pages/currencies/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/currencies/:id',
    name: 'currencie-show',
    meta: {
      authRequired: true,
      module: PermissionsModules.CURRENCIES
    },
    component: () => import('../views/pages/currencies/show'),
    beforeEnter: roleGuard,
  },

  /* EXCHANGE APIS */
  {
    path: '/exchange-apis',
    name: 'exchange apis',
    component: () => import('../views/pages/exchangeApis/exchangeApis'),
    beforeEnter: roleGuard,
    meta: {
      name: 'Exchange Apis',
    },
  },
  /* CRUPIERS APIS */
  {
    path: '/crupiers',
    name: 'cripiers apis',
    component: () => import('../views/pages/Crupiers/index'),
    beforeEnter: roleGuard,
    meta: {
      name: 'Crupiers',
      module: PermissionsModules.CRUPIERS
    },
  },
  /* RUTAS CLIENTS  */
  {
    path: '/clients',
    name: 'clients-index',
    meta: {
      name: 'Clientes',
      authRequired: true,
      module: PermissionsModules.CLIENTS
    },
    component: () => import('../views/pages/clients/index'),
    beforeEnter: clientPassGuard,
  },
  {
    path: '/clients/:id',
    name: 'client-show',
    meta: {
      authRequired: true,
      module: PermissionsModules.CLIENTS
    },
    component: () => import('../views/pages/clients/show'),
    beforeEnter: roleGuard,
  },
  /* RUTAS JUGADORES  */
  {
    path: '/players',
    name: 'players-index',
    meta: {
      authRequired: true,
      module: PermissionsModules.PLAYERS
    },
    component: () => import('../views/pages/players/index'),
    beforeEnter: roleGuard,
  },
  /* RUTAS OPERATORS  */
  {
    path: '/operators',
    name: 'operators-index',
    meta: {
      name: 'Operadores',
      authRequired: true,
      module: PermissionsModules.OPERATORS
    },
    component: () => import('../views/pages/operators/index'),
    beforeEnter: operatorPassGuard,
  },
  {
    path: '/operators/:id',
    name: 'operators-show',
    meta: {
      authRequired: true,
      module: PermissionsModules.OPERATORS
    },
    component: () => import('../views/pages/operators/components/show.vue'),
    beforeEnter: roleGuard,
  },

  /* RUTAS ROULETTES  */
  {
    path: '/roulettes',
    name: 'roulettes-index',
    meta: {
      name: 'Ruletas Virtuales',
      parent: 'Ruletas',
      authRequired: true,
      module: `${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/games/roulettes/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/roulettes-fisics',
    name: 'roulettes-fisics',
    component: () => import('../views/pages/games/roulettes-fisics/index'),
    beforeEnter: roleGuard,
    meta: {
      name: 'Ruletas Fisicas',
      module: `${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
    },
  },
  {
    path: '/roulettes/:id',
    name: 'roulettes-show',
    meta: {
      authRequired: true,
      module: `${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
    },
    component: () => import('../views/pages/games/roulettes/show'),
    beforeEnter: roleGuard,
  },

  /* RUTAS TIGER DRAGON */
  // /tigerDragon
  // /tigerDragon-fisics

  {
    path: '/tigerDragon',
    name: 'tiger-dragon-index',
    meta: {
      name: 'Tigre Dragón Virtual',
      parent: 'Tigre Dragón',
      // authRequired: true,
      // beforeResolve(routeTo, routeFrom, next) {
      //   let schema = setUserAction(routeTo.name, routeFrom.name, "");
      //   store.commit("actions/setActionsMut", schema);
      //   next();
      // },
    },
    component: () => import('../views/pages/games/tigerDragon/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/tigerDragon-fisics',
    name: 'tiger-dragon-fisics',
    meta: {
      name: 'Tigre Dragón Físico',
      parent: 'Tigre Dragón',
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/games/tigerDragon-fisics/index'),
    beforeEnter: roleGuard,
  },

  // EXTERNAL GAMES
  {
    path: '/external-games',
    name: 'external-games-index',
    meta: {
      name: 'Juegos Externos',
      parent: 'Juegos Externos',
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/games/externalGames/index'),
    beforeEnter: roleGuard,
  },
  // WHEELS
  {
    path: '/sprint-wheels',
    name: 'sprint-wheels-index',
    meta: {
      name: 'Rueda de la suerte',
      parent: 'Rueda de la suerte',
      module: PermissionsModules.WHEEL_FORTUNE,
      authRequired: true,
    },
    component: () => import('../views/pages/games/wheels/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/sprint-wheels/play/:id',
    name: 'sprint-wheels-play',
    meta: {
      name: 'sprint-wheels-play',
      parent: 'sprint-wheels-play',
      authRequired: true,
    },
    component: () =>
      import('../views/pages/games/wheels/components/Emulator.vue'),
    beforeEnter: roleGuard,
  },
  // BLACK JACK
  {
    path: '/black-jack',
    name: 'black-jack-index',
    meta: {
      name: 'Black Jack',
      parent: 'Black Jack',
      authRequired: true,
    },
    component: () => import('../views/pages/games/black-jack/index.vue'),
    beforeEnter: roleGuard,
  },
  /* MANUAL PAYMENTS */
  {
    path: '/manual-payment/individual',
    name: 'individual-payment',
    meta: {
      name: 'Individual',
      authRequired: true,
      module: PermissionsModules.INDIVIDUAL_PAYMENTS,
    },
    component: () => import('../views/pages/manualPayment/individualPayment'),
    beforeEnter: roleGuard,
  },
  {
    path: '/manual-payment/general',
    name: 'general-payment',
    meta: {
      name: 'General',
      authRequired: true,
      module: PermissionsModules.GENERAL_PAYMENTS,
    },
    component: () => import('../views/pages/manualPayment/generalPayment'),
    beforeEnter: roleGuard,
  },

  /* RUTAS Reportes  */
  {
    path: '/reports/auditory',
    name: 'reports-auditory',
    // meta: {
    //   authRequired: true,
    //   beforeResolve(routeTo, routeFrom, next) {
    //     let schema = setUserAction(routeTo.name, routeFrom.name, "");
    //     store.commit("actions/setActionsMut", schema);
    //     next();
    //   },
    // },
    component: () => import('../views/pages/reports/auditory/index'),
    beforeEnter: roleGuard,
    meta: {
      name: 'Auditoria de acciones',
      module: PermissionsModules.REPORTS,
    },
  },
  {
    path: '/reports/currencies',
    name: 'reports-currencies',
    // meta: {
    //   authRequired: true,
    //   beforeResolve(routeTo, routeFrom, next) {
    //     let schema = setUserAction(routeTo.name, routeFrom.name, "");
    //     store.commit("actions/setActionsMut", schema);
    //     next();
    //   },
    // },
    component: () => import('../views/pages/reports/currencies/index'),
    beforeEnter: roleGuard,
    meta: {
      name: 'Fluctacion de monedas',
    },
  },
  {
    path: '/reports/transations',
    name: 'reports-transations',
    meta: {
      name: 'Transacciones',
      authRequired: true,
      module: PermissionsModules.REPORTS,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/reports/transactions/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/reports/jackpots',
    name: 'reports-jackpots',
    meta: {
      name: 'Jackpots',
      authRequired: true,
      module: PermissionsModules.REPORTS,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/reports/jackpots/index'),
    beforeEnter: roleGuard
  },
  {
    path: '/reports/histories',
    name: 'reports-histories',
    meta: {
      name: 'Historial',
      authRequired: true,
      module: PermissionsModules.REPORTS,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/reports/history/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/reports/players',
    name: 'reports-players',
    meta: {
      name: 'reports-players',
      authRequired: true,
      module: PermissionsModules.REPORTS,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/reports/players/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/reports/players/:id',
    name: 'reports-player',
    meta: {
      authRequired: true,
      module: PermissionsModules.REPORTS,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/reports/players/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/reports/rounds',
    name: 'reports-rounds',
    meta: {
      name: 'Rondas',
      authRequired: true,
      module: PermissionsModules.REPORTS,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/reports/rounds/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/reports/ggr',
    name: 'reports-ggr',
    meta: {
      name: 'ggr',
      authRequired: true,
      module: PermissionsModules.GGR_GAME,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/reports/ggr/index'),
    beforeEnter: roleGuard,
  },
  // GGR BY CASINO
  {
    path: '/reports/ggr-by-casino',
    name: 'reports-ggr-by-casino',
    meta: {
      name: 'ggrByCasino',
      authRequired: true,
      module: PermissionsModules.GGR_CASINO,
      beforeResolve(routeTo, routeFrom, next) {
        let schema = setUserAction(routeTo.name, routeFrom.name, '');
        store.commit('actions/setActionsMut', schema);
        next();
      },
    },
    component: () => import('../views/pages/reports/ggrByCasino/index'),
    beforeEnter: roleGuard,
  },
  // GGR CASINO - END
  {
    path: '/reports/ggr/detail',
    name: 'reports-ggr-detail',
    meta: {
      authRequired: true,
      module: PermissionsModules.GGR_CASINO,
    },
    component: () => import('../views/pages/reports/ggr/detail'),
    beforeEnter: roleGuard,
  },
  {
    path: '/reports/ggr-by-wl',
    name: 'reports-ggr-by-wl',
    meta: {
      name: 'ggrByWl',
      authRequired: true,
      module: PermissionsModules.GGR_WL,
    },
    component: () => import('../views/pages/reports/ggrByWl/index'),
    beforeEnter: roleGuard
  },
  {
    path: '/reports/provider-logs',
    name: 'provider-logs',
    meta: {
      name: 'Resultados del proveedor',
      authRequired: true,
      module: PermissionsModules.REPORTS,

    },
    component: () => import('../views/pages/reports/providerLogs/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/reports/bet-logs',
    name: 'bet-logs',
    meta: {
      name: i18n.t('betLogs.title'),
      authRequired: true,
    },
    component: () => import('../views/pages/reports/betLogs/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/reports/views-bets-by-round',
    name: 'views-bets-by-round',
    meta: {
      name: 'Visitas / Apuestas',
      authRequired: true,
    },
    component: () => import('../views/pages/reports/bets/index'),
    // beforeEnter: roleGuard,
  },
  {
    path: '/reports/players-online',
    name: 'Jugadores en linea',
    meta: {
      name: 'Jugadores en linea',
      authRequired: true,
    },
    component: () => import('../views/pages/reports/players/PlayersOnline'),
    // beforeEnter: roleGuard,
  },
  {
    path: '/technical-technical/',
    name: 'reports-detail-technical-players',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/reports/players/detail/index'),
  },
  {
    path: '/technical-support',
    name: 'support-index',
    meta: {
      name: 'Soporte tecnico',
      authRequired: true,
    },
    component: () => import('../views/pages/support/index'),
  },
  /* SETTING -> RECUPERACION DE CONTRASEÑA */
  {
    path: '/settings',
    name: 'user-settings',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/settings/index'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/account/resetPassword'),
  },
  {
    path: '/incidents',
    name: 'Incidents',
    meta: {
      authRequired: true,
      module: PermissionsModules.INCIDENTS,
    },
    component: () => import('../views/pages/incidents/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/help',
    name: 'Help',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/userHelp/userHelp'),
  },
  {
    path: '/tablero/:id',
    name: 'tablero',
    component: () => import('../components/tablero/tablero'),
  },
  {
    path: '/tigerDragonEmulator',
    name: 'tigerDragonEmulator',
    component: () => import('../components/tablero/tigerDragonEmulator'),
  },
  {
    path: '/manual-result',
    name: 'ManualResult',
    component: () => import('../components/tablero/ManualResult'),
  },
  {
    path: '/ranking',
    name: 'RouletteRanking',
    component: () => import('../views/pages/games/roulettes/Ranking'),
  },
  {
    path: '/roles',
    name: 'RolesView',
    meta: {
      module: PermissionsModules.ROLES,
    },
    component: () => import('../views/pages/roles/index'),
    beforeEnter: roleGuard,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('../views/pages/utility/404'),
  },
];

import { axiosIns } from '../../helpers/axios/index';

export const state = {
  roles: [],
  success: false,
};

export const getters = {
  getRoles() {
    return state.roles;
  },
  getSuccess() {
    return state.success
  }
};

export const mutations = {
  setRoles(state, payload) {
    state.roles = [];
    state.roles = payload;
  },
  setSuccess(state, payload) {
    state.success = payload
  },

};

export const actions = {
  async create({ commit }, { data }) {
    try {

      const { status } = await axiosIns.post('/roles', data);

      if (status > 201) throw new Error('ERROR CREATING ROLE')

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR CREATING ROLE', error);
      commit('setSuccess', false)
    }
  },
  async update({ commit }, { id, data }) {
    try {

      const { status } = await axiosIns.put(`/roles/${id}`, data);

      if (status > 201) throw new Error('ERROR UPDATING ROLE')

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR UPDATING ROLE', error);
      commit('setSuccess', false)
    }
  },
  async remove({ commit }, { id }) {
    try {

      const { status } = await axiosIns.delete(`/roles/${id}`);

      if (status > 204) throw new Error('ERROR REMOVING ROLES')

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR REMOVING ROLES', error);
      commit('setSuccess', false)
    }
  },
  async fetchRoles({ commit }) {
    try {
      const { data } = await axiosIns.get('/roles');
      commit('setRoles', data.filter(el => el.status));
    } catch (error) {
      console.log('ERROR FETCH ROLES', error);
      commit('setRoles', []);
    }
  },
};

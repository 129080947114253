import {
  axiosIns,
  axiosInsWheel,
  axiosInsBlackJack,
  axiosInsDragonTiger,
} from '../../helpers/axios/index';
import { routes } from '../../helpers/axios/apiRoutes';
import store from '../store';
import {
  formatData as formatCollections,
  mergeChips,
} from '../helpers/formaDataApi';
import { requestSuccess } from '../helpers/requestSuccess';
import { sprint_gaming_apis_active } from '../../helpers/axios/wheelApi';
import { GamesTypes, responseTransactionGameType } from '../helpers/gamesTypes';
import { PermissionsModules } from '../../helpers/CONSTANTS';

const initialResolveBets = {
  mainResolveBet: {
    blackjack: 1.5,
    blackjackWithSpadeCards: 3,
    twentyOneWithThreeSeven: 10,
    twentyOneWithSevenCards: 10,
    twentyOneWithAnotherCombination: 1,
    winByPoints: 1,
    lose: -1,
    draw: 0,
  },
  perfectCoupleResolveBet: {
    perfectCouple: 25,
    colorCouples: 12,
    mixCouple: 6,
    lose: -1,
    draw: 0,
  },
  safeResolveBet: {
    win: 2,
    lose: -1,
  },
  twentyThreePlusOneResolveBet: {
    threeOfASameSuit: 100,
    straightOfASameSuit: 40,
    three: 30,
    straight: 10,
    color: 5,
    lose: -1,
    draw: 0,
  },
};

export const state = {
  currencies: [],
  roulettes: [],
  success: false,
  operator: {},
  errorMsg: null,
  games: [],
  chips: [],
  externalGames: [],
  operators: [],
  limits: [],
  operatorGames: [],
  dragonTigers: [],
  wheels: [],
  blackJack: [],
  resolveBets: initialResolveBets,
  loading: false,
};

export const getters = {
  getOperatorGames() {
    return state.operatorGames;
  },
  getCurrencies() {
    return state.currencies;
  },
  getRoulettes() {
    return state.roulettes;
  },
  getOperator() {
    return state.operator;
  },
  getOperators() {
    return state.operators;
  },
  getSuccess() {
    return state.success;
  },
  getErrorMessage() {
    return state.errorMsg;
  },
  getGamesByClientId() {
    return state.games;
  },
  getChips() {
    return state.chips;
  },
  getExternalGames() {
    return state.externalGames;
  },
  getLimits() {
    return state.limits;
  },
  getDragonTigers() {
    return state.dragonTigers;
  },
  getWheels() {
    return state.wheels.map(
      ({ game, order, configPayment, currencyAndLimits }) => ({
        ...game,
        order,
        configPayment,
        currencyAndLimits,
      })
    );
  },
  getBlackJack() {
    if (!state.blackJack) return [];

    return state.blackJack.map(({ game, currencyAndLimits }) => ({
      ...game,
      currencyAndLimits,
    }));
  },
  getResolveBets() {
    return state.resolveBets;
  },
  getFieldsBlackJack() {
    return [
      {
        key: 'name',
        sortable: true,
        label: 'NOMBRE',
        class: 'space-nowrap text-center',
      },
      {
        key: 'actions',
        sortable: true,
        label: 'ACCIONES',
        class: 'space-nowrap text-center',
      },
    ];
  },
  getLimitsFormatted:
    () =>
    (currencies, baseLimits, game, version = 'V1') => {
      const availables = currencies.filter((curr) => curr.currency);
      const limits = availables.map(
        ({
          figure,
          currency,
          short,
          uuid,
          minBet: minBetCurr,
          maxBet: maxBetCurr,
        }) => {
          const { minBet, maxBet } = baseLimits[short] || {
            minBet: 0,
            maxBet: 0,
          };

          const doc = {
            short,
            gameUuid: game.uuid,
            [version === 'V2' ? 'uuid' : 'currencyUuid']: uuid,
            minBet: minBet || minBetCurr,
            maxBet: maxBet || maxBetCurr,
            currency,
            figure,
          };
          return doc;
        }
      );
      return limits;
    },
  getNewResolveBets: () => (pays, baseResolveBets) => {
    const {
      mainResolveBet: baseMain,
      perfectCoupleResolveBet: basePerfectCouple,
      safeResolveBet: baseSafe,
      twentyThreePlusOneResolveBet: baseTwentyThreePlus,
    } = baseResolveBets;

    const {
      mainResolveBet: newMain,
      perfectCoupleResolveBet: newPerfectCouple,
      safeResolveBet: newSafe,
      twentyThreePlusOneResolveBet: newTwentyThree,
    } = pays;

    const resolveBet = {
      mainResolveBet: { ...baseMain, ...newMain },
      perfectCoupleResolveBet: {
        ...basePerfectCouple,
        ...newPerfectCouple,
      },
      safeResolveBet: { ...baseSafe, ...newSafe },
      twentyThreePlusOneResolveBet: {
        ...baseTwentyThreePlus,
        ...newTwentyThree,
      },
    };

    return resolveBet;
  },
  getLoading() {
    return state.loading;
  },
};

export const mutations = {
  setOperator(state, payload) {
    state.operator = {};
    state.operator = payload;
  },
  setOperators(state, payload) {
    state.operators = [];
    state.operators = payload;
  },
  setCurrencies(state, payload) {
    state.currencies = [];
    state.currencies = payload;
  },
  setRoulettes(state, payload) {
    state.roulettes = [];
    state.roulettes = payload;
  },
  setMsg(state, payload) {
    state.errorMsg = null;
    state.errorMsg = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setGamesByClientId(state, payload) {
    state.games = [];
    state.games = payload;
  },
  setChips(state, payload) {
    state.chips = [];
    state.chips = payload;
  },
  setExternalGames(state, payload) {
    state.externalGames = [];
    state.externalGames = payload;
  },
  setLimits(state, payload) {
    state.limits = payload;
  },
  setOperatorGames(state, payload) {
    state.operatorGames = payload;
  },
  setDragonTigers(state, payload) {
    state.dragonTigers = payload;
  },
  setWheels(state, payload) {
    state.wheels = payload;
  },
  setBlackJack(state, payload) {
    state.blackJack = payload;
  },
  setResolveBets(state, payload) {
    state.resolveBets = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  async create({ commit }, { doc }) {
    let uuid = '1';
    try {
      const { client, ...rest } = doc;
      const dataToRoulleteApi = { ...rest, client: client._id };
      const dataToAdminApi = {
        ...rest,
        client: client.uuid,
        casinoToken: client.token,
        minBet: 1,
        maxBet: 10,
      };

      try {
        const { data: adminApi } = await axiosInsWheel.post(
          'operator/create',
          dataToAdminApi
        );
        if (adminApi.operator) uuid = adminApi.operator.uuid;
      } catch (error) {
        console.log('ERORR CREEATING OPERATOR IN WHEEL', error);
        store.commit('notification/setErrors', [
          { reason: error, api: process.env.VUE_APP_WHEEL },
        ]);
      }

      const { status, data } = await axiosIns.post('operators', {
        ...dataToRoulleteApi,
        uuid,
      });

      if (!status > 201) return commit('setSuccess', false);

      commit('setSuccess', true);

      const { operator } = data;

      const createOperator = {
        userName: operator.name.replace(/ /g, ''),
        name: operator.name,
        lastName: operator.name,
        email: operator.name.replace(/ /g, ''),
        password: dataToRoulleteApi.password,
        role: dataToRoulleteApi.role,
        rol: 'OPERATOR',
        operator: operator._id,
        client: operator.client,
        tokenWallet: operator.client,
      };

      await store.dispatch('user/create', createOperator);
    } catch (error) {
      console.log('ERROR CREATE OPERATOR', error);
    }
  },
  async update({ commit }, { doc }) {
    try {
      const { client, ...rest } = doc;
      const dataToRoulleteApi = { ...rest, client: client._id };
      const dataToAdminApi = {
        ...rest,
        client: client.uuid,
        token: client.token,
        casinoToken: client.token,
        minBet: 1,
        maxBet: 10,
      };

      const { _id, ...dataToUpdate } = dataToRoulleteApi;
      const { uuid, ...dataToAdmin } = dataToAdminApi;

      let data = null;

      if (!uuid) {
        let adminApi = null;
        let adminStatus = null;

        try {
          const { data, status } = await axiosInsWheel.post(
            `operator/create`,
            dataToAdmin
          );

          adminApi = data;
          adminStatus = status;
        } catch (error) {
          console.log('ERROR CREATING OPERATOR WHEEL', error);
          store.commit('notification/setErrors', [
            { reason: error, api: process.env.VUE_APP_WHEEL },
          ]);
        }

        if (adminStatus > 201) {
          const rouletteApi = axiosIns.put(`operators/${_id}`, dataToUpdate);
          data = await Promise.allSettled([rouletteApi]);
        } else {
          const uuid = adminApi ? adminApi.operator.uuid : 1;
          const rouletteApi = axiosIns.put(`operators/${_id}`, {
            ...dataToUpdate,
            uuid,
          });
          data = await Promise.allSettled([rouletteApi]);
        }
      } else {
        const adminApi = axiosInsWheel.put(`operator/${uuid}`, dataToAdminApi);
        const rouletteApi = axiosIns.put(`operators/${_id}`, {
          ...dataToUpdate,
          uuid,
        });
        data = await Promise.allSettled([rouletteApi, adminApi]);
      }

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR CREATE OPERATOR', error);
    }
  },
  async remove({ commit }, { operator }) {
    try {
      const { _id, uuid } = operator;
      const rouletteApi = axiosIns.delete(`operators/${_id}`);
      const adminApi = axiosInsWheel.delete(`operator/remove/${uuid}`);

      const data = await Promise.allSettled([rouletteApi, adminApi]);
      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);
      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR REMOVING OPERATOR', error);
    }
  },
  async fetchOperatorCurrencies({ commit }, { path, id }) {
    try {
      const { data } = await axiosIns.get(`${path}/${id}/currencies`);

      if (!data.ok) {
        commit('setCurrencies', []);
        return false;
      }

      const currencies = data.currencies.map((curr) => curr);
      commit(
        'setCurrencies',
        formatOperatorCurrencies(
          currencies,
          store.getters['currencies/getCurrencies']
        )
      );
      return data;
    } catch (err) {
      console.log('Error fetchGameCurrencies', err);
      commit('setCurrencies', []);
    }
  },
  async addCurrencies({ commit }, { path, currencies, id }) {
    try {
      const { data } = await axiosIns.post(`${path}/${id}/add-currencies`, {
        currencies,
      });
      // /:uuid/add-currency
      // axiosInsDragonTiger.put()
      if (!data.ok) {
        return data;
      }

      commit('setCurrencies', data.currenciesSaved);
      return data;
    } catch (error) {
      console.log('ERROR addCurrencies', error);
    }
  },
  async updateCurrencies({ commit }, { path, id, currencies }) {
    try {
      const { data } = await axiosIns.put(`${path}/${id}/update-currencies`, {
        currencies,
      });

      if (!data.ok) {
        return data;
      }

      commit('setCurrencies', data.currencies);
      return data;
    } catch (error) {
      console.log('ERROR UPDATE CURRENCIES', error);
    }
  },
  async fetchOperatorsByClientId({ commit }, { filterData }) {
    const { gameType } = filterData;

    try {
      const operators = [];

      switch (gameType) {
        case GamesTypes.Roulette: {
          const { clientId: client } = filterData;
          const { data } = await axiosIns.get(`/operators?client=${client}`);
          if (data.operators) {
            operators.push(...data.operators);
          }
          break;
        }
        case GamesTypes.DragonTiger: {
          const { clientId: client } = filterData;

          const clientExist = store.getters['clients/getClients'].find(
            (gm) => gm._id === client
          );
          const { uuid } = clientExist;

          const { data, status } = await axiosInsDragonTiger.get(
            `/operator/client/${uuid}`
          );

          if (data.operators && status < 201) {
            operators.push(...data.operators);
          }
          break;
        }
        case GamesTypes.ExternalGames: {
          const { clientId: client } = filterData;
          const { data } = await axiosIns.get(`/operators?client=${client}`);
          if (data.operators) {
            operators.push(...data.operators);
          }
          break;
        }
        default:
          break;
      }

      commit('setOperators', operators);
    } catch (error) {
      console.log(
        'ERROR FETCH OPERATORS BY CLIENT ID -> OPERATOR MODULE',
        error
      );
    }
  },
  // ! URL
  async updateUrl({ commit }, { operator, endpoints }) {
    const { _id, uuid } = operator;
    try {
      const rouletteApi = axiosIns.put(
        `operators/update-url/${_id}`,
        endpoints
      );
      const dragonApi = axiosInsWheel.put(
        `operator/${uuid}/update-urls`,
        endpoints
      );

      const data = await Promise.allSettled([rouletteApi, dragonApi]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR UPDATE URL -> OPERATORS STATE', error);
      commit('setSuccess', false);
    }
  },
  // ! chips
  async addChips({ commit }, { path, id, form }) {
    try {
      const { data } = await axiosIns.post(`${path}/${id}/assign-chips`, {
        ...form,
      });

      if (!data.ok) return data;

      commit('setCurrencies', []);
      return data;
    } catch (error) {
      console.log('ERROR ADD CHIPS', error);
      return false;
    }
  },
  async findChips({ commit }, { path, operator, filter }) {
    const { _id: id, uuid } = operator;

    try {
      const { data: rouletteApi } = await axiosIns.get(
        `${path}/${id}/show-chips`,
        {
          params: { ...filter },
        }
      );

      const adminApi = await store.dispatch('operators/findChipsDragon', {
        uuid,
        filter,
      });

      const chips = formatOperatorChips([rouletteApi, adminApi]);

      commit('setCurrencies', []);
      commit('setChips', chips);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR IN FIND CHIPS', error);
      commit('setChips', []);
      commit('setSuccess', false);
      return false;
    }
  },
  async findChipsDragon(_, { uuid, filter }) {
    const { currencyId: currency } = filter;
    const filterCurrency = {};

    if (currency) {
      const currencyExist = store.getters['currencies/getCurrencies'].find(
        (gm) => gm._id === currency
      );
      const { short } = currencyExist;
      filterCurrency['currency'] = short ? short : 'X';
    }

    try {
      const { data, status } = await axiosInsWheel.get(
        `operator/${uuid}/chips`,
        { params: { ...filter, ...filterCurrency } }
      );

      if (status > 201) return null;

      return data;
    } catch (error) {
      console.log('ERROR FIND CHIPS DRAGON', error);
      return null;
    }
  },
  async updateChip({ commit }, { operator, newChip }) {
    try {
      const { _id, uuid } = operator;
      const { _id: chipId, uuid: chipUuid, ...restChip } = newChip;
      console.log('newChip', newChip);

      const rouletteApi = axiosIns.put(`operators/${_id}/update-chips`, {
        chipId,
        newChip: { ...restChip },
      });

      const adminApi = store.dispatch('operators/updateChipDragon', {
        uuid,
        chipUuid,
        restChip,
      });

      const data = await Promise.allSettled([rouletteApi, adminApi]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR UPDATE CHIP', error);
      commit('setSuccess', false);
    }
  },
  updateChipDragon(_, { uuid, chipUuid, restChip }) {
    try {
      return axiosInsWheel.put(`operator/${uuid}/update-chip/${chipUuid}`, {
        newChip: {
          ...restChip,
          currency: restChip.currencyShort,
        },
      });
    } catch (error) {
      console.log('ERROR UPDATE CHIP DRAGON', error);
      return null;
    }
  },
  // ! operator roulettes
  async modifyOpRoulette({ commit }, { opId, rouId, model }) {
    try {
      const { data } = await axiosIns.put(
        `/operators/${opId}/update-roulette/${rouId}`,
        model
      );

      if (!data.ok) return commit('setSuccess', false);

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR MODIFYING OP ROULETTE', error);
      commit('setSuccess', false);
    }
  },
  async getOperatorRoulettes({ commit }, { id }) {
    try {

      addModuleInstance(`${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`)

      const { data } = await axiosIns.get(`operators/${id}/roulettes`);

      if (!data.ok) {
        commit('setMsg', data.msg ? data.msg : 'Error desconocido');
        return commit('setSuccess', false);
      }

      commit('setOperator', data.operatorRoullettes[0].operator);

      const roulettes = data.operatorRoullettes.map((opRltts) => {
        return {
          ...opRltts.roulette,
          operatorRoulette: opRltts._id,
          currencies: opRltts.currencies,
          //TODO: AGREGAR LOS MONTOS DE APUESTAS DEL OP
          pleno: opRltts.pleno || opRltts.roulette.pleno,
          semipleno: opRltts.semipleno || opRltts.roulette.semipleno,
          cuadro: opRltts.cuadro || opRltts.roulette.cuadro,
          calle: opRltts.calle || opRltts.roulette.calle,
          linea: opRltts.linea || opRltts.roulette.linea,
          columna: opRltts.columna || opRltts.roulette.columna,
          docena: opRltts.docena || opRltts.roulette.docena,
          chanceSimple: opRltts.chanceSimple || opRltts.roulette.chanceSimple,
          cubre: opRltts.cubre || opRltts.roulette.cubre,
          specialCalle: opRltts.specialCalle || opRltts.roulette.specialCalle,
          logo: opRltts.logo || opRltts.roulette.logo,
          operator: opRltts.operator,
          layout: opRltts.layout,
          template: opRltts.template,
        };
      });
      commit('setRoulettes', roulettes);
      commit('setSuccess', true);
    } catch (error) {
      console.log('error get operator roulettes', error);
    }
  },
  async updateOperatorRoulettes({ commit }, { operatorId, _id, dataToUpdate }) {
    try {

      addModuleInstance(`${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`)

      const { data } = await axiosIns.put(
        `operators/${operatorId}/update-roulette/${_id}`,
        dataToUpdate
      );

      if (!data.ok) throw new Error('Error updating operator roulettes')

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR UPDATING OPERATOR ROULETTES', error);
      commit('setSuccess', false)
    }
  },
  async removeOperatorRoulettes({ commit }, { id }) {
    try {

      addModuleInstance(`${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`)

      const { data } = await axiosIns.delete(
        `operators/${id}/delete-roulette`
      );

      if (!data.ok) throw new Error('Error removing operator roulettes')

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR REMOVE OPERATOR ROULETTES', error);
      commit('setSuccess', false);
    }
  },
  // !Listgame
  async getGamesByClientId({ commit }, { path, id }) {
    try {
      const { data } = await axiosIns.get(`/clients/${id}/${path}`); //example: /:id/roulettes

      const roulettes = data.clientRoulettes.map(({ roulette }) => roulette);
      commit('setGamesByClientId', roulettes);
    } catch (error) {
      console.log('ERROR GET GAMES', error);
    }
  },
  async fetchExternalGames({ commit }, { id }) {
    try {
      const { data } = await axiosIns.get(`/operators/${id}/external-games`);

      if (!data.ok) return commit('setExternalGames', []);

      commit('setExternalGames', data.operatorGames);
    } catch (error) {
      console.log('ERROR FECTH EXTERNAL GAMES', error);
      commit('setExternalGames', []);
    }
  },
  async fetchOperators({ commit }, { options = {}, optionsAdminApi = {} }) {
    commit('setLoading', true);

    addModuleInstance(PermissionsModules.OPERATORS)

    try {
      const { data, status } = await axiosIns.get('/operators', options);
      const filter = { ...options, ...optionsAdminApi };

      if (status > 200) {
        commit('setLoading', false);
        return commit('setOperators', []);
      }

      if (data.error) {
        commit('setLoading', false);
        return commit('setOperators', []);
      }

      const operators_admin_api = sprint_gaming_apis_active
        ? await store.dispatch('operators/fetchOperatorsAdminApi', {
            options: filter,
          })
        : [];

      const operators = formatCollections(
        data.operators,
        operators_admin_api.filter(
          (op) => op?.status && data.operators.find((i) => i.name === op.name)
        )
      );

      commit('setOperators', operators);
      commit('setLoading', false);
    } catch (error) {
      console.log('ERROR FETCH OPERATORS', error);
      commit('setLoading', false);
    }
  },
  async fetchOperatorsAdminApi(_, { options }) {
    try {
      let routeOperator;

      const routesOperator = routes.admin_api.operators;

      const filterUuid = await store.dispatch('reports/getFilterUuid', {
        filter: options,
        type: GamesTypes.WheelFortune,
      });

      if (Object.entries(filterUuid).length) {
        const { client } = filterUuid;

        if (client) {
          routeOperator = `${routesOperator.path}/${routesOperator.client}/${client}`;
        }
      } else {
        routeOperator = `${routesOperator.path}`;
      }

      const { data, status } = await axiosInsWheel.get(routeOperator);

      if (status > 201) return [];

      if (data.error) return [];

      return data.operators;
    } catch (error) {
      console.log('ERROR fetchOperatorsAdminApi', error);
      return [];
    }
  },
  async addGame({ commit }, { operatorId, rouletteId, endpoint }) {
    try {
      const { data } = await axiosIns.post(
        `/operators/${operatorId}/${endpoint}`,
        { rouletteId }
      );
      if (!data.ok) return commit('setSuccess', false);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR ADD GAME', error);
    }
  },
  async addWheel({ commit }, { operatorId: uuid, wheelLimits }) {
    const limits = Object.values(wheelLimits);

    try {
      const rqs = limits.map(async (limit) => {
        return await axiosInsWheel.patch(
          `operator/${uuid}/add-game-and-limits`,
          limit
        );
      });
      const data = await Promise.allSettled([...rqs]);

      const { success } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR ADD GAME', error);
      commit('setSuccess', false);
    }
  },
  async addBlackJack({ commit }, { operatorId: uuid, limits }) {
    try {
      const rqs = limits.map(async (limit) => {
        return await axiosInsBlackJack.patch(
          `operator/${uuid}/add-game-and-limits`,
          limit
        );
      });
      const data = await Promise.allSettled([...rqs]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);
      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR ADD GAME', error);
      commit('setSuccess', false);
    }
  },
  //  !LIMITS
  //  :id/currency-limits/:roulette
  async fetchOpertorRouletteLimits({ commit }, { operatorId, rouletteId }) {
    try {
      const { data } = await axiosIns.get(
        `/operators/${operatorId}/currency-limits/${rouletteId}`
      );

      if (!data.ok) return commit('setSuccess', false);

      commit('setLimits', data.limits);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR GETTING OPERATOR ROULETTE LIMITS', error);
      commit('setSuccess', false);
    }
  },
  async updateOperatorRouletteLimits({ commit }, { id, limits, rouletteId }) {
    try {
      const { data } = await axiosIns.put(
        `/operators/${id}/update-currency-limits`,
        {
          limits,
          rouletteId,
        }
      );
      if (!data.ok) return commit('setSuccess', false);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR UPDATING OPERATOR ROULETTE LIMITS', error);
      commit('setSuccess', false);
    }
  },
  async fetchOperatorGames({ commit }, { id }) {
    let games = [];
    try {
      games = await loopGames(id);

      commit('setSuccess', true);
      commit('setOperatorGames', games);
    } catch (error) {
      console.log('ERROR FETCHING OPERATOR GAMES', error);
    }
  },
  async fetchOperatorWheels({ commit }, { uuid }) {
    try {
      const { data, status } = await axiosInsWheel.get(
        `operator/${uuid}/games`
      );

      if (status > 201) commit('setWheels', []);

      commit('setWheels', data.wheels);
    } catch (error) {
      console.log('ERROR FETCHING OPERATOR WHEELS', error);
    }
  },
  async fetchOperatorBlackJack({ commit }, { uuid }) {
    try {
      const { data, status } = await axiosInsBlackJack.get(
        `operator/${uuid}/games`
      );

      if (status > 201) commit('setBlackJack', []);

      commit('setBlackJack', data.games);
    } catch (error) {
      console.log('ERROR FETCHING OPERATOR BLACK JACK', error);
    }
  },
  async saveOrderGames({ commit }, { id, games, uuid }) {
    try {
      let queries = createQueries(id, games, uuid);
      await Promise.all([...queries]);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR SAVE ORDER GAMES', error);
      commit('setSuccess', false);
    }
  },
  // DRAGON TIGERS
  async fetchDragonTigers({ commit }, { uuid }) {
    const operatorRoutes = routes.admin_api.operators;
    const route = `${operatorRoutes.path}/${uuid}/${operatorRoutes.games}`;

    try {
      const { data, status } = await axiosInsDragonTiger.get(route);

      if (status > 201) return commit('setDragonTigers', []);
      if (data.error) return commit('setDragonTigers', []);

      commit('setDragonTigers', data.games);
    } catch (error) {
      console.log('ERROR FETCH DRAGON TIGERS');
    }
  },
  async editPayments({ commit }, { gameUuid, uuid, dataToUpdate }) {
    const operatorRoutes = routes.admin_api.operators;
    const route = `${operatorRoutes.path}/${uuid}/${operatorRoutes.editGame}/${gameUuid}/add-payments`;
    try {
      const payments = { game: gameUuid, ...dataToUpdate };
      const { data } = await axiosInsDragonTiger.put(route, payments);

      if (data.error) return commit('setSuccess', false);

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR EDIT PAYMENTS', error);
      commit('setSuccess', false);
    }
  },
  // LIMITS
  async updateLimits({ commit }, { limits, operator, game }) {
    try {
      const { uuid } = operator;
      const { uuid: gameUuid } = game;
      const rqs = limits.map(async (limit) => {
        return await axiosInsDragonTiger.put(
          `operator/${uuid}/update-limits-to-game/${gameUuid}`,
          limit
        );
      });
      const data = await Promise.all([...rqs]);

      const success = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR EDIT PAYMENTS', error);
      commit('setSuccess', false);
    }
  },
  async removeLimit({ commit }, { operator, game, currency }) {
    const { uuid } = operator;
    const { uuid: gameUuid } = game;
    try {
      const { data } = await axiosInsDragonTiger.delete(
        `operator/${uuid}/remove-limit-to-game/${gameUuid}`,
        {
          params: {
            currency,
          },
        }
      );

      if (data.error) return commit('setSuccess', false);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR EDIT PAYMENTS', error);
      commit('setSuccess', false);
    }
  },
  async removeLimitWheel({ commit }, limit) {
    const { operator: uuid, game: gameUuid } = limit;
    try {
      const { status } = await axiosInsWheel.patch(
        `operator/V2/${uuid}/add-game-and-limits/${gameUuid}`,
        {
          limits: [limit],
        }
      );

      if (status > 400) return commit('setSuccess', false);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR EDIT LIMITS WHEEL', error);
      commit('setSuccess', false);
    }
  },
  async changeStatusWheel({ commit }, { uuid, currencyUuid, gameUuid }) {
    try {
      const { status } = await axiosInsWheel.patch(
        `operator/${uuid}/change-game-status/${gameUuid}`,
        { currencyUuid }
      );

      if (status >= 400) throw new Error('ERROR CHANGING STATUS');

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR CHANGING STATUS WHEEL', error);
      commit('setSuccess', false);
    }
  },

  // *CHIPS*
  async defaultChips({ commit }, { operator, currency }) {
    const { _id, uuid } = operator;
    const { _id: currencyId, short: isoCode } = currency;

    try {
      const rouletteReq = axiosIns.post(`/operators/${_id}/default-chips`, {
        currencyId,
      });

      const adminReq = axiosInsWheel.post(
        `operator/${uuid}/default-chips/${isoCode}`
      );

      const data = await Promise.allSettled([rouletteReq, adminReq]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);
      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR IN DEFAULT CHIPS', error);
      commit('setSuccess', false);
    }
  },
  async assignChips({ commit }, { operator, doc }) {
    const { _id, uuid } = operator;
    const currency = operator.currencies.find(
      (cr) => cr._id === doc.currency._id
    );

    const chipForAdmin = {
      value: doc.value,
      primaryColor: doc.color.primary,
      secondaryColor: doc.color.secondary,
      currency: currency.uuid,
      operator: uuid,
    };

    try {
      const rouletteReq = axiosIns.post(`/operators/${_id}/assign-chips`, {
        chipToSave: {
          ...doc,
          currency: currency._id,
        },
      });

      const adminApi = store.dispatch('operators/addChipDragon', {
        chipForAdmin,
      });

      const data = await Promise.allSettled([rouletteReq, adminApi]);
      console.log('data', data);
      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      const { value } = data[1];
      if (value) {
        const { data } = value;

        const addedToOperator = await store.dispatch(
          'operators/addChipOperatorDragon',
          {
            adminApi: data,
            uuid,
          }
        );

        if (addedToOperator) {
          commit('setSuccess', true);
        } else {
          commit('setSuccess', false);
        }
      } else {
        commit('setSuccess', true);
        store.commit('notification/setErrors', errors);
      }
    } catch (error) {
      console.log('ERROR ASSIGN CHIPS', error);
    }
  },
  async addChipDragon(_, { chipForAdmin }) {
    try {
      return axiosInsWheel.post('chip', chipForAdmin);
    } catch (error) {
      console.log('ERROR ADD CHIP DRAGON', error);
      return null;
    }
  },
  async addChipOperatorDragon(_, { uuid, adminApi }) {
    try {
      const { chipCreated } = adminApi;

      const { data } = await axiosInsWheel.put(
        `/operator/${uuid}/assign-chip`,
        {
          chipUuid: chipCreated.uuid,
        }
      );

      if (data.error) return null;

      return data;
    } catch (error) {
      console.log('ERROR ADD CHIP FOR OPERATOR DRAGON', error);
      return null;
    }
  },
  async removeChip({ commit }, { operator, chip }) {
    try {
      const { uuid } = operator;

      const { uuid: chipUuid, _id } = chip;

      const rouletteApi = axiosIns.delete(`operators/${_id}/delete-chip`);
      const adminApi = store.dispatch('operators/removeChipDragon', {
        uuid,
        chipUuid,
      });

      const data = await Promise.allSettled([rouletteApi, adminApi]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      store.commit('notification/setErrors', errors);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR REMOVE CHIP', error);
      commit('setSuccess', false);
    }
  },
  removeChipDragon(_, { uuid, chipUuid }) {
    try {
      return axiosInsWheel.delete(`operator/${uuid}/delete-chip/${chipUuid}`);
    } catch (error) {
      console.log('ERROR REMOVE CHIP DRAGON', error);
      return null;
    }
  },
  // WHEEL LIMITS
  async setPayments({ commit }, { configPayments, uuid, gameUuid }) {
    try {
      const { status } = await axiosInsWheel.patch(
        `operator/${uuid}/add-config-payments/${gameUuid}`,
        { configPayments }
      );
      if (status > 201) return commit('setSuccess', false);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR SET PAYMETNS => operator store module', error);
      commit('setSuccess', false);
    }
  },
  getPaysToEdit(_, { games, operator, item }) {
    const defaultPays = games
      .find((game) => game.uuid === item.uuid)
      .betPays.map((item) => ({
        optionBet: item.number,
        pay: item.multiplier,
      }));
    const defaultPaysDoc = defaultPays.reduce(
      (acc, value) => Object.assign(acc, { [Number(value.optionBet)]: value }),
      {}
    );
    const gamesPaysInOperator = operator.operatorGamesLimits
      .find((i) => i.game.uuid === item.uuid)
      .configPayment.reduce(
        (acc, value) =>
          Object.assign(acc, { [Number(value.optionBet)]: value }),
        {}
      );
    const paysMerged = { ...defaultPaysDoc, ...gamesPaysInOperator };
    const paysToEdit = Object.values(paysMerged);
    return paysToEdit;
  },
  // BLACK JACK
  async fetchResolveBets({ commit }, { gameUuid, operatorUuid }) {
    try {
      const { data, status } = await axiosInsBlackJack.get(
        `resolve-bet/${gameUuid}/${operatorUuid}`
      );

      if (status > 201) {
        commit('setSuccess', false);
        return commit('setResolveBets', initialResolveBets);
      }

      commit('setSuccess', true);
      commit('setResolveBets', data.resolveBet);
    } catch (error) {
      console.log('ERROR FETCH RESOLVE BETS', error);
      commit('setSuccess', false);
    }
  },
  async addResolveBets({ commit }, { operatorUuid, gameUuid, resolveBet }) {
    try {
      const { status } = await axiosInsBlackJack.post('resolve-bet', {
        operatorUuid,
        gameUuid,
        resolveBet,
      });

      if (status > 201) return commit('setSuccess', false);

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR ADD RESOLVE BETS', error);
      commit('setSuccess', false);
    }
  },
  async updateResolveBets({ commit }, { payments, gameUuid, operatorUuid }) {
    try {
      const {
        mainResolveBet,
        perfectCoupleResolveBet,
        safeResolveBet,
        twentyThreePlusOneResolveBet,
      } = payments;

      const reqmainBet = axiosInsBlackJack.put(
        `resolve-bet/mainBet/${gameUuid}/${operatorUuid}`,
        { mainResolveBet }
      );
      const reqperfectCoupleBet = axiosInsBlackJack.put(
        `resolve-bet/perfectCoupleBet/${gameUuid}/${operatorUuid}`,
        { perfectCoupleResolveBet }
      );
      const reqsafeBet = axiosInsBlackJack.put(
        `resolve-bet/safeBet/${gameUuid}/${operatorUuid}`,
        { safeResolveBet }
      );
      const reqtwentyThreePlusOneBet = axiosInsBlackJack.put(
        `resolve-bet/twentyThreePlusOneBet/${gameUuid}/${operatorUuid}`,
        { twentyThreePlusOneResolveBet }
      );

      const data = await Promise.allSettled([
        reqmainBet,
        reqperfectCoupleBet,
        reqsafeBet,
        reqtwentyThreePlusOneBet,
      ]);
      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);
      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR UPDATE RESOLVE BETS', error);
    }
  },
  async fetchGames(_, filter) {
    try {
      const { gameType, operatorId } = filter;
      let games = [];

      switch (gameType) {
        case GamesTypes.Roulette:
          {
            await store.dispatch('operators/getOperatorRoulettes', {
              id: operatorId,
            });
            games = store.getters['operators/getRoulettes'];
          }
          break;
        case GamesTypes.WheelFortune:
          {
            let filterUuid = await store.dispatch('reports/getFilterUuid', {
              filter,
              type: GamesTypes.WheelFortune,
            });

            await store.dispatch('operators/fetchOperatorWheels', {
              uuid: filterUuid.operator,
            });

            games = store.getters['operators/getWheels'];
          }
          break;

        default:
          break;
      }

      return games;
    } catch (error) {
      console.log('ERROR FETCH GAMES OPERATOR', error);
      return [];
    }
  },
  async fetchOperatorLimits(_, filter) {
    try {
      const { uuid, gameUuid } = filter;

      const { data, status } = await axiosInsWheel.get(
        `/operator/V2/${uuid}/add-game-and-limits/${gameUuid}`
      );

      if (status > 400) throw new Error('ERROR');

      return data.operatorLimits;
    } catch (error) {
      console.log('ERROR GETTING OPERATOR LIMITS', error);
      return [];
    }
  },
  async addOperatorLimits(_, { limits, operator, game }) {
    try {
      const { status } = await axiosInsWheel.post(
        `/operator/V2/${operator}/add-game-and-limits/${game}`,
        { limits }
      );

      if (status > 400) throw new Error('ERROR CREATING OPERATOR LIMITS');

      return true;
    } catch (error) {
      console.log('ERROR ADDING OPERATOR LIMITS', error);
      return false;
    }
  },
  async updateOperatorLimits(_, { limits, operator, game }) {
    try {
      const { status } = await axiosInsWheel.put(
        `/operator/V2/${operator}/add-game-and-limits/${game}`,
        { limits }
      );

      if (status > 400) throw new Error('ERROR UPDATING OPERATOR LIMITS');

      return true;
    } catch (error) {
      console.log('ERROR ADDING OPERATOR LIMITS', error);
      return false;
    }
  },
};

const gamesUrls = [
  {
    path: 'roulettes',
    response: 'operatorRoullettes',
    game: 'roulette',
    update: 'update-roulette',
  },
  {
    path: 'external-games',
    response: 'operatorGames',
    game: 'externalGame',
    update: 'update-external-game',
  },
];

const getGames = async (id, gameUrl, response, game, update) => {
  try {
    const { data } = await axiosIns.get(`/operators/${id}/${gameUrl}`);

    if (!data.ok) return null;

    return { game, update, games: data[response] };
  } catch (error) {
    console.log('ERORR GET GAMES', error);
  }
};

const loopGames = async (id) => {
  const games = [];

  const operator = store.getters['operators/getOperators'].find(
    ({ _id }) => _id === id
  );

  const uuid = operator.uuid || 'x';

  for (let i = 0; i < gamesUrls.length; i++) {
    const { path, response, game, update } = gamesUrls[i];

    const data = await getGames(id, path, response, game, update);

    if (!data) continue;

    const formattedData = formatData(data);
    games.push(...formattedData);
  }

  await store.dispatch('operators/fetchOperatorWheels', { uuid });
  const formatTypeWheel = formatData({
    game: 'WHEEL',
    games: store.getters['operators/getWheels'].map((game) => ({
      WHEEL: game,
      order: game.order,
      operator,
    })),
    update: 'update-game-limits',
  });

  games.push(...formatTypeWheel);

  return games;
};

const formatData = (data) => {
  const { game: model, games: operatorGames, update } = data;
  const formatted = [];
  for (let i = 0; i < operatorGames.length; i++) {
    const { _id } = operatorGames[i];
    const game = operatorGames[i][model];

    if (!game) continue;

    game.type = responseTransactionGameType[game.type];

    switch (game.type) {
      case GamesTypes.Roulette:
        {
          const order =
            typeof operatorGames[i].order === 'number'
              ? operatorGames[i].order
              : null;
          formatted.push({ _id, game, order, update });
        }
        break;
      case GamesTypes.ExternalGames:
        {
          const order =
            typeof operatorGames[i].order === 'number'
              ? operatorGames[i].order
              : null;
          formatted.push({ _id, game, order, update });
        }
        break;
      case GamesTypes.WheelFortune:
        {
          const order =
            typeof operatorGames[i].order === 'number'
              ? operatorGames[i].order
              : null;
          formatted.push({ _id: game.uuid, game, order, update });
        }
        break;

      default:
        break;
    }
  }

  return formatted;
};

const createQueries = (id, games, uuid) => {
  const queries = [];
  const operatorUuid = uuid;
  for (let i = 0; i < games.length; i++) {
    const { update: path, game, order } = games[i];
    const { _id: idGame, type, uuid } = game;

    switch (type) {
      case GamesTypes.Roulette:
        {
          const url = `/operators/${id}/${path}/${idGame}`;
          const query = axiosIns.put(url, { order });
          queries.push(query);
        }
        break;
      case GamesTypes.ExternalGames:
        {
          const url = `/operators/${id}/${path}/${idGame}`;
          const query = axiosIns.put(url, { order });
          queries.push(query);
        }
        break;
      case GamesTypes.WheelFortune:
        {
          const url = `/operator/${operatorUuid}/${path}/${uuid}`;
          const query = axiosInsWheel.patch(url, { order });
          queries.push(query);
        }
        break;

      default:
        break;
    }
    // '/:uuid/update-game-limits/:gameUuid'
  }

  return queries;
};

export const formatPayments = (games = [], operator) => {
  const { gamesPayments } = operator;

  if (!gamesPayments.length) return games;

  const gamesFormatted = [];

  for (let game of games) {
    const { uuid } = game;
    const paymentToUpdate = gamesPayments.find((gm) => gm.game === uuid);
    if (!paymentToUpdate) {
      const gameWithOutPays = { ...game };
      gamesFormatted.push(gameWithOutPays);
      continue;
    }
    const {
      chanceSimple,
      tie,
      perfectTie,
      jackpot,
      goldenK,
      color,
      size,
      parity,
    } = paymentToUpdate;
    const gameUpdated = {
      ...game,
      chanceSimple,
      tie,
      perfectTie,
      jackpot,
      goldenK,
      color,
      size,
      parity,
    };
    gamesFormatted.push(gameUpdated);
  }

  return gamesFormatted;
};

export const formatLimits = (limits, currencies) => {
  const gameWithLimits = [];
  const gameWithOutLimits = [];
  const currenciesWithLimits = [];
  const currenciesWithOutLimits = [];
  let isUpdating = false;

  for (let currency of currencies) {
    const filteredLimit = limits.find((limit) => limit.currency === currency);
    if (!filteredLimit) {
      currenciesWithOutLimits.push(currency);
      gameWithOutLimits.push({
        currency,
        minBet: null,
        maxBet: null,
        maxBetPosition: null,
      });
      continue;
    }

    gameWithLimits.push(filteredLimit);
    currenciesWithLimits.push(currency);
    isUpdating = true;
  }

  return {
    gameWithLimits,
    gameWithOutLimits,
    currenciesWithLimits,
    currenciesWithOutLimits,
    isUpdating,
  };
};

export const fomatLimitBet = (limits, currencies, betType, gameUuid) => {
  const gameWithLimits = [];
  const gameWithOutLimits = [];
  const currenciesWithLimits = [];
  const currenciesWithOutLimits = [];

  let isUpdating = false;

  for (let currency of currencies) {
    const limitsBet = limits.find(
      (limit) =>
        limit.game === gameUuid && limit[betType] && limit.currency === currency
    );

    if (!limitsBet) {
      currenciesWithOutLimits.push(currency);
      gameWithOutLimits.push({
        currency,
        minBet: null,
        maxBet: null,
        maxBetPosition: null,
      });
      continue;
    }

    gameWithLimits.push({
      currency,
      minBet: limitsBet[betType].minBet,
      maxBet: limitsBet[betType].maxBet,
      maxBetPosition: null,
    });
    currenciesWithLimits.push(currency);
    isUpdating = true;
  }

  return {
    gameWithLimits,
    gameWithOutLimits,
    currenciesWithLimits,
    currenciesWithOutLimits,
    isUpdating,
  };
};

export const formatLimitsToSave = (operator, limits, betType) => {
  const newLimits = [];
  const { limits: limitsOperator } = operator;
  for (let limit of limitsOperator) {
    const { currency, game } = limit;
    const limitToUpdate = limits.find(
      (lm) => lm.currency === currency && lm.game === game
    );
    if (!limitToUpdate) continue;
    const { minBet, maxBet } = limitToUpdate;

    if (!minBet && !maxBet) continue;

    const newLimit = {
      ...limit,
      [betType]: {
        minBet,
        maxBet,
      },
    };

    newLimits.push(newLimit);
  }

  return newLimits;
};

export const formatChips = (chips, currency) => {
  const formatted = chips.map((chip) => {
    return {
      ...chip,
      primaryColor: chip.color.primary,
      secondaryColor: chip.color.secondary,
      currency: currency.uuid,
    };
  });

  return formatted;
};

const formatOperatorCurrencies = (opCurrencies, modelCurrencies) => {
  const formatted = [];
  for (let curr of opCurrencies) {
    const currency = modelCurrencies.find((cr) => cr.short === curr.short);
    if (currency) {
      formatted.push(currency);
      continue;
    }
    formatted.push(curr);
  }

  return formatted;
};

const formatOperatorChips = (res) => {
  const chipsToMerge = [];
  for (let data of res) {
    if (!data) continue;
    const { chips } = data;
    chipsToMerge.push(chips);
  }
  const formatted = mergeChips(chipsToMerge[0], chipsToMerge[1]);

  return formatted;
};

function addModuleInstance(module) {
  axiosIns.defaults.headers['store'] = module;
}
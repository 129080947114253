import { axiosIns, axiosInsWheel } from '../../helpers/axios/index';
import { PermissionsModules } from '../../helpers/CONSTANTS';
import { requestSuccess } from '../helpers/requestSuccess';
import store from '../store';

export const state = {
  players: [],
  success: false,
  message: '',
};
export const getters = {
  getPlayers() {
    return state.players;
  },
  getSuccess() {
    return state.success;
  },
  getMessage() {
    return state.message;
  },
};
export const mutations = {
  setPlayers(state, payload) {
    state.players = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setMessage(state, payload) {
    state.message = payload;
  },
};
export const actions = {
  async fetchPlayerRound({ commit }, { options }) {
    try {

      addModuleToInstance(PermissionsModules.PLAYERS)

      const { data, status } = await axiosIns.get('/player-round/getPlayer', {
        ...options,
      });

      if (status > 200) {
        commit('setSuccess', false);
        return commit('setPlayers', []);
      }
      if (data.error) {
        commit('setSuccess', false);
        return commit('setPlayers', []);
      }

      commit('setSuccess', true);
      commit('setPlayers', data.res.map(el => {
        return {
          ...el,
          updatedAt: el.updatedAt ? new Date(el.updatedAt).toLocaleString() : null,
          operator: el.operator.name
        }
      }));
    } catch (error) {
      console.log('ERROR FETCH PLAYER ROUND', error);
    }
  },
  async fetchPlayersByTerm({ commit
  }, { term }) {
    try {

      addModuleToInstance(PermissionsModules.PLAYERS)

      const { data, status } = await axiosIns.get(`/player-round/find-by-term`, {
        params: {
          term,
        },
      });

      if (status > 400) {
        commit('setSuccess', false);
        return commit('setPlayers', []);
      }
      if (data.error) {
        commit('setSuccess', false);
        return commit('setPlayers', []);
      }

      commit('setSuccess', true);
      commit('setPlayers', data.players.map(el => {
        return {
          ...el,
          updatedAt: el.updatedAt ? new Date(el.updatedAt).toLocaleString() : null,
          operator: el.operator.name
        }
      }));
    } catch (error) {
      console.log('ERROR FETCH PLAYER ROUND', error);
      commit('setPlayers', []);
    }
  },
  async changePlayerAdmin({ commit }, { player, isAdmin }) {
    try {

      addModuleToInstance(PermissionsModules.PLAYERS)

      const rouletteApi = await axiosIns.put(
        `player-round/${player._id}/player-admin`,
        { isAdmin }
      );
      const adminApi = await axiosInsWheel.put(
        `players/${player.tokenWallet}/player-admin`,
        { isAdmin }
      );

      const data = await Promise.allSettled([rouletteApi, adminApi]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR changePlayerAdmin', error);
      commit('setSuccess', false);
    }
  },
  async changePlayerPhysic({ commit }, { player, isPhysic, board, type }) {
    try {

      addModuleToInstance(PermissionsModules.PLAYERS)

      const rouletteApi = await axiosIns.put(
        `player-round/${player._id}/player-physic`,
        { isPhysic, board }
      );

      const wheelApi = await axiosInsWheel.put(
        `players/${player.tokenWallet}`,
        { isPhysic, board, type }
      );

      const data = await Promise.allSettled([rouletteApi, wheelApi]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR changePlayerPhysic', error);
      commit('setSuccess', false);
    }
  },
  async closeWheelSession({ commit }, { player, gameUuid }) {
    const { userId } = player;

    try {
      const { status } = await axiosInsWheel.delete(
        `players/${userId}/remove-session/${gameUuid}`
      );

      if (status >= 400) throw new Error('ERROR REMOVIN PLAYER');

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR CLOSE WHEEL SESSION', error);
      commit('setSuccess', false);
    }
  },
  async closeRouletteSession({ commit }, { player, gameUuid }) {
    const { _id } = player;

    try {

      addModuleToInstance(`${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`)

      const { status, data } = await axiosIns.delete(
        `player-round/${_id}/remove-session/${gameUuid}`
      );

      if (status >= 400) throw new Error('ERROR REMOVIN PLAYER');
      if (data.msg === 'Session not found')
        throw new Error('Session not found');

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR CLOSE ROULETTE SESSION', error);
      commit('setSuccess', false);
      commit('setMessage', error.message);
    }
  },
  async updatePlayer({ commit }, { id, status }) {

    try {

      addModuleToInstance(PermissionsModules.PLAYERS)

      const { data } = await axiosIns.put('/player-round/updatePlayer/', {
        id,
        status,
      })

      if (!data.ok) throw new Error('ERROR UPDATE PLAYER');

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR UPDATE PLAYER', error);
      commit('setSuccess', false);
    }
  },
};

function addModuleToInstance(module) {
  axiosIns.defaults.headers['store'] = module
}
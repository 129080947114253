import { axiosIns } from "../../helpers/axios"
import { hierarchyStructure } from '../helpers/hierarchy-structure';

export const state = {
  permissions: [],
  success: false,
  current_permission: null,
  hierarchyStructureBase: hierarchyStructure,

}

export const getters = {
  getPermissions() {
    return state.permissions
  },
  getSuccess() {
    return state.success
  },
  getCurrentPermission() {
    return state.current_permission
  },
  getHierarchyStructureBase() {
    return state.hierarchyStructureBase;
  }
}

export const mutations = {
  setPermissions(state, payload) {
    state.permissions = []
    state.permissions = payload
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setCurrentPermission(state, payload) {
    state.current_permission = payload
  },
  updateReadValue(state, { id, value, key }) {
    const updateValue = (items) => {
      items.forEach(item => {
        if (item.id === id) item[key] = value;

        if (item.children) {
          updateValue(item.children);
        }
      });
    };
    updateValue(state.hierarchyStructureBase);
  },
  setHierarchyStructureBase(state, payload) {
    state.hierarchyStructureBase = payload;
  },
  resetHierarchyStructureBase(state) {
    state.hierarchyStructureBase = hierarchyStructure;
  }
}

export const actions = {
  async fetch({ commit }) {
    try {
      const { data } = await axiosIns.get('/permissions')
      commit('setPermissions', data.filter(e => e.status))
    } catch (error) {
      console.log('ERROR FETCHING PERMISSIONS', error)
      commit('setPermissions', [])
    }
  },
  async findManyBy({ commit }, filter) {

    const { roleId } = filter

    try {
      const { data } = await axiosIns.get(`/permissions/${roleId}/many-by`, {
        params: { roleId }
      })
      commit('setPermissions', data.filter(e => e.status))
    } catch (error) {
      console.log('ERROR FETCHING PERMISSIONS', error)
      commit('setPermissions', [])
    }
  },
  async create({ commit }, { data }) {
    try {
      const { status } = await axiosIns.post('/permissions', data)
      if (status > 201) throw new Error('ERROR CREATING PERMISSION')
      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR CREATING PERMISSION', error)
      commit('setSuccess', false)
    }
  },
  async createMany({ commit }, { permissions }) {
    try {

      const { status } = await axiosIns.post('/permissions/many', { permissions })

      if (status > 201) throw new Error('ERROR CREATING MANY PERMISSIONS')

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR CREATING MANY PERMISSIONS', error);
      commit('setSuccess', false)
    }

    commit('resetHierarchyStructureBase')
  },
  async update({ commit }, { id, data }) {
    try {
      const { status } = await axiosIns.patch(`/permissions/${id}`, data)
      if (status > 201) throw new Error('ERROR UPDATING PERMISSION')
      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR UPDATING PERMISSION', error)
      commit('setSuccess', false)
    }

    commit('resetHierarchyStructureBase')
  },

  async updateMany({ commit }, { permissions }) {
    try {
      const { status } = await axiosIns.patch('/permissions/v1/many', { permissions })
      if (status > 201) throw new Error('ERROR UPDATING MANY PERMISSIONS')
      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR UPDATING MANY PERMISSIONS', error)
      commit('setSuccess', false)
    }
  },

  async remove({ commit }, { id }) {
    try {
      const { status } = await axiosIns.delete(`/permissions/${id}`)
      if (status > 204) throw new Error('ERROR DELETING PERMISSION')
      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR DELETING PERMISSION', error)
      commit('setSuccess', false)
    }
  },
  updateReadValue({ commit }, payload) {
    commit('updateReadValue', payload);
  },
  //! Function to flatten permissions for backend
  flattenPermissions(_, { array, roleId }) {
    const result = [];

    function recurse(items) {
      for (const item of items) {
        const { module } = item;

        if (module) {
          const permission = {
            roleId,
            module,
            canRead: item.canRead || false,
            canWrite: item.canWrite || false,
            canUpdate: item.canUpdate || false,
            canDelete: item.canDelete || false,
          };

          if (item._id) Object.assign(permission, { _id: item._id });

          result.push(permission);
        }

        if (item.children) {
          recurse(item.children);
        }
      }
    }

    recurse(array);
    return result;
  },
  //! Function to build nested permissions from backend
  buildNestedPermissions(_, permissions) {
    const root = [];

    function findOrCreateNode(path, parent) {
      const name = path.shift();
      let node = parent.find((item) => item.name === name);

      if (!node) {
        node = { name, children: [] };
        parent.push(node);
      }

      if (path.length > 0) {
        return findOrCreateNode(path, node.children);
      }

      return node;
    }

    for (const permission of permissions) {
      const path = permission.module.split('/');
      const node = findOrCreateNode(path, root);

      node.module = permission.module;
      node.canRead = permission.canRead;
      node.canWrite = permission.canWrite;
      node.canUpdate = permission.canUpdate;
      node.canDelete = permission.canDelete;

      node.readView = true;
      node.createView = true;
      node.updateView = true;
      node.deleteView = true;

      node.id = crypto.randomUUID()
      node._id = permission._id
    }

    return root;
  },
}
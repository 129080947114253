import { axiosIns } from '../../helpers/axios/index';
import store from '../store';

export const state = {
  success: false,
  round: null,
  incidence: false,
};

export const getters = {
  isSuccess() {
    return state.success;
  },
  isIncidence() {
    return state.incidence;
  },
};

export const mutations = {
  setSuccess(state, payload) {
    state.success = payload;
  },
  setRound(state, payload) {
    state.round = payload;
  },
  setIncidence(state, payload) {
    state.incidence = payload;
  },
};

export const actions = {
  async closeRound({ commit }, { ID_Ronda, ID_Ruleta, Resultado }) {
    try {
      const result = {
        ID_Ronda,
        ID_Ruleta,
        Resultado,
        Giro: 'AH',
        Rpm: '18',
        Error: '0',
        Fecha: new Date().toDateString(),
      };

      const { data } = await axiosIns.put(`/round/end`, result);

      const { token } = data;

      if (token) store.dispatch('auth/updateTokenInInstances', { token });

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR CLOSE ROUND', error);

      const log = {
        request: {
          ID_Ronda,
          ID_Ruleta,
          Resultado,
        },
        response: error || error.message,
        error: error || error.message,
        success: false,
        ip: '1.1.1.1',
        type: 'round',
      };

      commit('setSuccess', false);

      await store.dispatch('logs/create', log);
      store.dispatch('logs/saveLogInLocalStorage', log);
    }
  },
  async startRound(_, { ID_Ronda, ID_Ruleta }) {
    try {
      await axiosIns.put('round/start', {
        ID_Ronda,
        ID_Ruleta,
        Fecha: new Date().toString(),
        Giro: '0',
        Rpm: '22',
        Error: '0',
        Resultado: '99',
      });
    } catch (error) {
      console.log('ERROR START ROUND', error);
      const log = {
        request: {
          ID_Ronda,
        },
        response: error,
        error: error.message,
        success: false,
        ip: '1.1.1.1',
        type: 'round',
      };

      await store.dispatch('logs/create', log);
      store.dispatch('logs/saveLogInLocalStorage', log);
      throw new Error('ERROR INICIANDO RONDA');
    }
  },
};

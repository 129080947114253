import axios from 'axios';
import Swal from 'sweetalert2';
import i18n from '../../i18n';
import store from '../../state/store';
import { PermissionsModules } from '../CONSTANTS';
const API_URL = process.env.VUE_APP_API;
const axiosIns = axios.create({
  headers: {
    'Content-type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
    'cache-control': 'no-cache',
  },
  // You can add your headers here
  // ================================
  baseURL: API_URL,
  // timeout: 1000,

  validateStatus: function (status) {
    return status >= 200 && status < 500; // defecto
  },
});
let user = JSON.parse(localStorage.getItem('user'));
let token = localStorage.getItem('tokenSession');
if (user) {
  axiosIns.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
}
if (token) {
  axiosIns.defaults.headers.common['x-token'] = token;
  axiosIns.defaults.headers.common['authentication-x-token'] = token;
}

axiosIns.interceptors.request.use((config) => {
  // Do something before request is sent
  // console.log('CONFIG', config);
  console.log('CURRENT MODULE', store.getters['permissions/getCurrentPermission'])

  const current_module = store.getters['permissions/getCurrentPermission']

  const moduleMapping = {
    [`${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`]: `${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
    [PermissionsModules.USERS]: PermissionsModules.USERS,
    [PermissionsModules.INDIVIDUAL_PAYMENTS]: PermissionsModules.INDIVIDUAL_PAYMENTS,
    [PermissionsModules.GENERAL_PAYMENTS]: PermissionsModules.GENERAL_PAYMENTS,
    [PermissionsModules.REPORTS]: PermissionsModules.REPORTS,
    [PermissionsModules.CRUPIERS]: PermissionsModules.CRUPIERS,
    [PermissionsModules.INCIDENTS]: PermissionsModules.INCIDENTS,
  };

  if (moduleMapping[current_module]) {
    config.headers['Module'] = moduleMapping[current_module];
    return config;
  }

  const { store: store_module } = config.headers
  const module = [current_module]

  if (store_module && current_module !== store_module) module.push(store_module)

  config.headers['Module'] = module.join('/')
  return config;
}, (error) => {
  // Do something with request error
  console.log('ERROR IN REQUEST INTERCEPTOR', error);
  return Promise.reject(error);
});


axiosIns.interceptors.response.use(
  async (response) => {
    if (response.status === 401) {
      await Swal.fire({
        title: i18n.t(`unathorize_messages.${response.status}`),
        text: i18n.t(`unathorize_messages.${response.data.msg}`),
        icon: 'warning',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 10000,
        showCloseButton: true,
      })
    }

    return response;
  },
  (error) => {
    // Do something with request error
    console.log('ERROR IN RESPONSE INTERCEPTOR', error);
    return Promise.reject(error);
  }
);

export default axiosIns;

export const formattGgrByGameAll = (data, filter) => {
  const { limit } = filter;

  const docs = [];

  for (let doc of data) {
    const { data } = doc.value;
    docs.push(...data);
  }

  const ggr = docs.slice(0, limit);

  let total_betsAmount = 0;
  let total_wins = 0;
  let total_rounds = 0;
  let total_profit = 0;

  for (let doc of ggr) {
    const { rounds, bets, wins } = doc;
    total_rounds += rounds;
    total_betsAmount += bets;
    total_wins += wins;
  }

  total_profit = total_betsAmount - total_wins;

  return {
    data: ggr,
    total: {
      total_betsAmount,
      total_wins,
      total_rounds,
      total_profit,
    },
  };
};

export const formatGgrByCasinoAll = (data) => {
  const values = data.reduce((acc, val) => acc.concat(...val.value.data), []);

  const totalizedValues = data.reduce(
    (acc, val) => acc.concat(val.value.total),
    []
  );

  const objectData = {};

  for (let doc of values) {
    const key = `${doc.name}-${doc.currency}`;

    if (objectData[key]) continue;

    Object.assign(objectData, {
      [key]: values.filter(
        (i) => i.name === doc.name && i.currency === doc.currency
      ),
    });
  }

  const formatted = [];

  for (let doc in objectData) {
    const values = objectData[doc];
    let rounds = 0;
    let bets = 0;
    let wins = 0;
    let profit = 0;

    for (let value of values) {
      rounds += value.rounds;
      bets += value.bets;
      wins += value.wins;
    }

    profit = bets - wins;

    formatted.push({
      rounds,
      bets,
      wins,
      profit,
      name: values[0].name,
      currency: values[0].currency,
    });
  }

  return {
    data: formatted,
    total: getTotal(totalizedValues),
  };
};

export const formatGgrByWlsAll = (data) => {
  const values = data.reduce((acc, val) => acc.concat(...val.value.data), []);

  const totalizedValues = data.reduce(
    (acc, val) => acc.concat(val.value.total),
    []
  );

  const objectData = {};

  for (let doc of values) {
    const key = `${doc.name}-${doc.operator}-${doc.currency}`;

    if (objectData[key]) continue;

    Object.assign(objectData, {
      [key]: values.filter(
        (i) =>
          i.name === doc.name &&
          i.operator === doc.operator &&
          i.currency === doc.currency
      ),
    });
  }

  const formatted = [];

  for (let doc in objectData) {
    const values = objectData[doc];

    const {
      total_betsAmount: bets,
      total_profit: profit,
      total_rounds: rounds,
      total_wins: wins,
    } = calculateTotal(values);

    formatted.push({
      rounds,
      bets,
      wins,
      profit,
      name: values[0].name,
      operator: values[0].operator,
      currency: values[0].currency,
    });
  }

  return {
    data: formatted,
    total: getTotal(totalizedValues),
  };
};

export const calculateTotal = (values) => {
  let total_rounds = 0;
  let total_betsAmount = 0;
  let total_wins = 0;
  let total_profit = 0;

  total_betsAmount;
  total_profit;
  total_rounds;
  total_wins;

  for (let value of values) {
    total_rounds += value.rounds;
    total_betsAmount += value.bets;
    total_wins += value.wins;
  }

  total_profit = total_betsAmount - total_wins;

  return {
    total_betsAmount,
    total_profit,
    total_rounds,
    total_wins,
  };
};

export const formatGgrByPlayerAll = (data) => {
  const values = data.reduce((acc, val) => acc.concat(...val.value), []);

  const objectData = {};

  for (let doc of values) {
    const key = `${doc.name}-${doc.currency.short}`;

    if (objectData[key]) continue;

    Object.assign(objectData, {
      [key]: values.filter(
        (i) => i.name === doc.name && i.currency.short === doc.currency.short
      ),
    });
  }

  const formatted = [];

  for (let doc in objectData) {
    const values = objectData[doc];
    let rounds = 0;
    let bets = 0;
    let wins = 0;
    let profit = 0;

    for (let value of values) {
      rounds += value.rounds;
      bets += value.bets;
      wins += value.wins;
    }

    profit = bets - wins;

    formatted.push({
      rounds,
      bets,
      wins,
      profit,
      name: values[0].name,
      userId: values[0].userId,
      operator: values[0].operator,
      currency: values[0].currency,
    });
  }

  return formatted;
};

export const getTotal = (data) => {
  let total_betsAmount = 0;
  let total_wins = 0;
  let total_rounds = 0;
  let total_profit = 0;

  for (let doc of data) {
    total_betsAmount += doc.total_betsAmount;
    total_wins += doc.total_wins;
    total_rounds += doc.total_rounds;
  }

  total_profit = total_betsAmount - total_wins;

  return {
    total_betsAmount,
    total_wins,
    total_rounds,
    total_profit,
  };
};

export const concatData = (data) => {
  const currencies = new Set(
    data.reduce((acc, val) => acc.concat(val.currency), [])
  );

  const newData = [];

  for (let curr of currencies) {
    const values = data.filter((doc) => doc.currency === curr);
    const formatted = values.reduce(
      (acc, val) => {
        acc.rounds += val.rounds;
        acc.wins += val.wins;
        acc.bets += val.bets;
        acc.profit += val.profit;
        acc.name = val.name;
        acc.currency = val.currency;
        acc.operator = val.operator;
        return acc;
      },
      { rounds: 0, bets: 0, wins: 0, profit: 0 }
    );

    newData.push(formatted);
  }

  return newData;
};

export const initialValue = {
  total_betsAmount: 0,
  total_profit: 0,
  total_rounds: 0,
  total_wins: 0,
};

export const concatTotalized = (data) => {
  const initialValue = {
    total_betsAmount: 0,
    total_profit: 0,
    total_rounds: 0,
    total_wins: 0,
  };

  return data.reduce((acc, val) => {
    acc.total_betsAmount += val.total_betsAmount;
    acc.total_profit += val.total_profit;
    acc.total_rounds += val.total_rounds;
    acc.total_wins += val.total_wins;
    return acc;
  }, initialValue);
};

export const getTotalByWls = (data) => {
  const initialValue = {
    total_betsAmount: 0,
    total_profit: 0,
    total_rounds: 0,
    total_wins: 0,
  };

  if (!data.length) return initialValue;

  return data.reduce((acc, val) => {
    acc.total_betsAmount += val.bets;
    acc.total_profit += val.profit;
    acc.total_rounds += val.rounds;
    acc.total_wins += val.wins;
    return acc;
  }, initialValue);
};

import Swal from 'sweetalert2';
import store from '../store';
import { requestSuccess } from '../helpers/requestSuccess';
import {
  axiosIns,
  axiosInsBlackJack as axiosBlackJack,
} from '../../helpers/axios/index';
import { GamesTypes } from '../helpers/gamesTypes';

export const state = {
  viewSelected: null,
  success: false,
  crupiers: [],
};

export const getters = {
  getViewSelected() {
    return state.viewSelected;
  },
  getSuccess() {
    return state.success;
  },
  getCrupiers() {
    return state.crupiers;
  },
};

export const mutations = {
  setViewSelected(state, payload) {
    state.viewSelected = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setCrupiers(state, payload) {
    state.crupiers = payload;
  },
};

export const actions = {
  async handleMenuOptions({ commit }) {
    const { value } = await Swal.fire({
      title: 'Selecciona una vista',
      input: 'select',
      inputOptions: {
        chat: 'Chat',
        dragonCounter: 'Contador Dragon Tiger',
        wheel: 'Rueda',
        ranking: 'Ranking',
        roulette: 'Ruletas'
      },
      inputPlaceholder: 'A donde te diriges?',
    });

    commit('setViewSelected', value);
  },
  async handleRouletteOptions() {

    Swal.fire({
      title: 'Cargando ruletas...',
      allowOutsideClick: false,
      showConfirmButton: false,
    })

    await store.dispatch('games/fetchGames', { gameType: GamesTypes.Roulette })

    const games = store.getters['games/getGames']
      .filter(game => game.isManualRoulette)
      .map(game => ({ _id: game._id, name: game.name, providerId: Number(game.providerId) }))
      .sort((a, b) => a.providerId - b.providerId);

    const inputOptions = games.reduce((acc, game) => Object.assign(acc, { [game._id]: `${game.name} - ${game.providerId}` }), {})

    const { value } = await Swal.fire({
      title: 'Selecciona una ruleta',
      input: 'select',
      inputPlaceholder: '---',
      allowOutsideClick: false,
      inputOptions,
    });

    const roulette = games.find(game => game._id === value);

    store.commit('games/setGame', roulette);
  },
  async store({ commit }, crupier) {
    try {
      const roulette = store.dispatch('crupiers/storeRoulette', crupier);
      const admin = store.dispatch('crupiers/storeAdmin', crupier);

      const data = await Promise.allSettled([roulette, admin]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERORR STRORE CRUPIER', error);
    }
  },
  async storeRoulette(_, crupier) {
    try {
      const data = await axiosIns.post('crupiers', crupier,);
      if (data.status > 201) return null;
      return data;
    } catch (error) {
      console.log('ERROR storeRoulette -> CRUPIERS MODULE ', error);
    }
  },
  async storeAdmin(_, crupier) {
    try {
      return axiosBlackJack.post('croupier/create', crupier);
    } catch (error) {
      console.log('ERROR storeAdmin -> CRUPIERS MODULE ', error);
    }
  },
  async update({ commit }, crupier) {
    try {
      const roulette = store.dispatch('crupiers/updateRoulette', crupier);
      const admin = store.dispatch('crupiers/updateAdmin', crupier);

      const data = await Promise.allSettled([roulette, admin]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR UPDATE MODULE CRUPIER', error);
    }
  },
  async updateRoulette(_, crupier) {
    try {
      return axiosIns.put(`crupiers/${crupier._id}`, crupier,);
    } catch (error) {
      console.log('ERORR UPDATE ROULETTE CRUPIER', error);
    }
  },
  async updateAdmin(_, crupier) {
    try {
      return axiosBlackJack.put(
        `croupier/update/${this.typeform.uuid}`,
        crupier
      );
    } catch (error) {
      console.log('ERORR UPDATE ADMIN CRUPIER', error);
    }
  },
  async fetchCrupiersAdmin({ commit }) {
    try {
      const { data, status } = await axiosBlackJack.get('croupier/all');
      if (status > 201) return commit('setCrupiers', []);

      const { croupiers } = data;

      commit('setCrupiers', croupiers);
    } catch (error) {
      console.log('ERROR FETCH CRUPIERS BLACK JACK', error);
    }
  },
  async fetchCrupiersRoulette({ commit }) {
    try {
      const { data, status } = await axiosIns.get('crupiers');
      if (status > 201) return commit('setCrupiers', []);

      const { crupiers } = data;

      commit('setCrupiers', crupiers);
    } catch (error) {
      console.log('ERROR FETCH CRUPIERS ROULETTE', error);
      commit('setCrupiers', []);
    }
  },
  async remove({ commit }, id) {
    try {

      const { status } = await axiosIns.delete(`crupiers/${id}`);

      if (status > 204) throw new Error('Error al eliminar crupier');

      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR REMOVE CRUPIER MODULE', error);
      commit('setSuccess', false)
    }
  }
};

import { PermissionsModules } from "../../helpers/CONSTANTS";

export const hierarchyStructure = [
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.DASHBOARD,
    module: PermissionsModules.DASHBOARD,
    readView: true,
    canRead: true,
    children: [
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.OPERATORS,
        module: `${PermissionsModules.DASHBOARD}/${PermissionsModules.OPERATORS}`,
        readView: true,
        canRead: true,
      },
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.GAMES,
        children: [
          {
            id: crypto.randomUUID(),
            name: PermissionsModules.ROULETTES,
            readView: true,
            canRead: true,
            module: `${PermissionsModules.DASHBOARD}/${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
          }
        ]
      }
    ]
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.CURRENCIES,
    module: PermissionsModules.CURRENCIES,
    readView: true,
    canRead: true,
    createView: true,
    canWrite: false,
    updateView: true,
    canUpdate: false,
    deleteView: true,
    canDelete: false,
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.CLIENTS,
    module: PermissionsModules.CLIENTS,
    readView: true,
    canRead: true,
    createView: true,
    canWrite: false,
    updateView: true,
    canUpdate: false,
    deleteView: true,
    canDelete: false,
    children: [
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.CURRENCIES,
        module: `${PermissionsModules.CLIENTS}/${PermissionsModules.CURRENCIES}`,
        readView: true,
        createView: true,
        updateView: true,
        canRead: true,
        canWrite: false,
        canUpdate: false,
      },
      {
        name: PermissionsModules.GAMES,
        id: crypto.randomUUID(),
        children: [
          {
            id: crypto.randomUUID(),
            name: PermissionsModules.ROULETTES,
            module: `${PermissionsModules.CLIENTS}/${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
            readView: true,
            canRead: true,
            createView: true,
            canWrite: false,
            updateView: true,
            canUpdate: false,
            deleteView: true,
            canDelete: false,
          }
        ]
      }
    ]
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.OPERATORS,
    module: PermissionsModules.OPERATORS,
    readView: true,
    createView: true,
    updateView: true,
    deleteView: true,
    canRead: true,
    canWrite: false,
    canUpdate: false,
    canDelete: false,
    children: [
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.CLIENTS,
        module: `${PermissionsModules.OPERATORS}/${PermissionsModules.CLIENTS}`,
        readView: true,
        createView: true,
        updateView: true,
        canRead: true,
        canWrite: false,
        canUpdate: false,
      },
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.CURRENCIES,
        module: `${PermissionsModules.OPERATORS}/${PermissionsModules.CURRENCIES}`,
        readView: true,
        createView: true,
        updateView: true,
        canRead: true,
        canWrite: false,
        canUpdate: false,
      },
      {
        name: PermissionsModules.GAMES,
        id: crypto.randomUUID(),
        children: [
          {
            id: crypto.randomUUID(),
            name: PermissionsModules.ROULETTES,
            module: `${PermissionsModules.OPERATORS}/${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
            readView: true,
            createView: true,
            updateView: true,
            deleteView: true,
            canRead: true,
            canWrite: false,
            canUpdate: false,
            canDelete: false,
          }
        ]
      }
    ]
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.PLAYERS,
    module: PermissionsModules.PLAYERS,
    readView: true,
    updateView: true,
    deleteView: true,
    canRead: true,
    canUpdate: false,
    canDelete: false,
    children: [
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.OPERATORS,
        module: `${PermissionsModules.PLAYERS}/${PermissionsModules.OPERATORS}`,
        readView: true,
        canRead: true,
      },
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.GAMES,
        module: PermissionsModules.GAMES,
        children: [
          {
            id: crypto.randomUUID(),
            name: PermissionsModules.ROULETTES,
            module: `${PermissionsModules.PLAYERS}/${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
            readView: true,
            createView: true,
            updateView: true,
            deleteView: true,
            canRead: true,
            canWrite: false,
            canUpdate: false,
            canDelete: false,
          }
        ]
      },
    ]
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.GAMES,
    children: [
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.ROULETTES,
        module: `${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
        readView: true,
        canRead: true,
        createView: true,
        canWrite: false,
        updateView: true,
        canUpdate: false,
        deleteView: true,
        canDelete: false,
      }
    ]
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.USERS,
    module: PermissionsModules.USERS,
    readView: true,
    canRead: true,
    createView: true,
    canWrite: false,
    updateView: true,
    canUpdate: false,
    deleteView: true,
    canDelete: false,
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.INDIVIDUAL_PAYMENTS,
    module: PermissionsModules.INDIVIDUAL_PAYMENTS,
    readView: true,
    createView: true,
    canRead: true,
    canWrite: false,
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.GENERAL_PAYMENTS,
    module: PermissionsModules.GENERAL_PAYMENTS,
    readView: true,
    createView: true,
    canRead: true,
    canWrite: false,
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.REPORTS,
    module: PermissionsModules.REPORTS,
    readView: true,
    canRead: true,
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.CRUPIERS,
    module: PermissionsModules.CRUPIERS,
    readView: true,
    createView: true,
    updateView: true,
    deleteView: true,
    canRead: true,
    canWrite: false,
    canUpdate: false,
    canDelete: false,
  },
  {
    id: crypto.randomUUID(),
    name: PermissionsModules.INCIDENTS,
    module: PermissionsModules.INCIDENTS,
    readView: true,
    createView: true,
    updateView: true,
    deleteView: true,
    canRead: true,
    canWrite: false,
    canUpdate: false,
    canDelete: false,
    children: [
      {
        id: crypto.randomUUID(),
        name: PermissionsModules.GAMES,
        module: `${PermissionsModules.INCIDENTS}/${PermissionsModules.GAMES}`,
        children: [
          {
            id: crypto.randomUUID(),
            name: PermissionsModules.ROULETTES,
            module: `${PermissionsModules.INCIDENTS}/${PermissionsModules.GAMES}/${PermissionsModules.ROULETTES}`,
            readView: true,
            createView: true,
            updateView: true,
            deleteView: true,
            canRead: true,
            canWrite: false,
            canUpdate: false,
            canDelete: false,
          }
        ]
      }
    ]
  },
]